import React, { useEffect, useCallback } from "react"
import styled from "styled-components"
import { navigate } from "gatsby-link";
import { useDispatch } from "react-redux"
import { fetchArticles } from "../../store/articles"
import { ArticlesService } from "../../api/articles"
import "../../template/md-style.css"
import "../../template/prism.css"
import { RESPONSIVE_STYLES } from "../../AppConst"


/**
 *@ Materials
 */
import Layout from "../../template/Layout"
import Head from "../../head"
import TagList from "../common/TagList"
import { ArticleHeading } from "../blogpost/ArticleHeading"
import { Author } from "./Author"
import { SNSShare } from "../blogpost/SNSShare"
import IconTwitter from "../../images/icon_twitter.svg"
import { Banner } from "./../common/Banner"
import { RoundLinkButton } from "../common/RoundLinkButton";
import { formateDate } from "../../utlis/formatDate";

import Prism from 'prismjs';
import { LoadingAPI } from "../common/LoadingAPI";

/**
 *@ Elements & Styles
 */
const MainContainer = styled.div`
  padding-bottom: 80px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-top: -25px;  // パンくずリストのpadding-bottomを相殺
    margin-right: auto;
    padding-bottom: 40px;
  }
`
const Node = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 64px 0;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 40px 0;
  }
`

const AuthorWrapper = styled.div`
  margin-top: 24px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-top: 16px;
  }
`
const TagsWrapper = styled.div`
  text-align: left;
  margin-top: 24px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-top: 16px;
  }
`
const ThumbnailWrapper = styled.div`
  margin: 64px 0 0;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 40px 0 0;
  }

  & > Img {
    display: block;
    width: 100%;
    height: auto;
  }
`
const AuthorWrapperBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  margin-top: 32px;
  padding: 24px;
  box-sizing: border-box;
  border: 1px solid #222;

  & .author_of_article {
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.8px;
  }

  & .flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  }

  & .read {
    width: 100%;
    max-width: 280px;
 }
`

const baseBreadcrumbsData: { text: string; path: string }[] = [
  { text: `BLOG`, path: `blog` },
]

const SNS: React.VFC<{member: any}> = ({member}) => {
  const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      gap: 8px;
    }
  `
  const IconWrapper = styled.a`
  `
  const Icon = styled.img`
    width: 48px;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      width: 24px;
    }
  `

  if (!member.twitter_url) {
    return null;
  }

  return (
    <Wrapper>
      <IconWrapper href={member.twitter_url} target="_blank" rel="noopener noreferrer">
        <Icon src={IconTwitter} alt={"Twitter"} />
      </IconWrapper>
    </Wrapper>
  )
}

const BannerWrapper = styled.div`
  width: 288px;
  margin: 96px auto;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 48px auto;
  }
`
type Props = {
  data: any
  postData: any
  isLoading: boolean
}

const Component = React.memo(function Component(props: Props) {

  if (!props.postData) {
    return (
      <>
        <Layout
          sidebar={true}
          hasBlogTitle={false}
          isBlogPostPage={true}
        >
          {
            props.isLoading && <LoadingAPI />
          }
        </Layout>
      </>
    )
  }

  const post = props.postData.frontmatter;

  const slug = props.postData.frontmatter.slug;
  const writer: { name: string, position: string, pathname: string, twitter_url: string, filename: string } = props.data.site.siteMetadata.member.find((m) => m.name == post.author);
  // const dispatch = useDispatch()
  const breadcrumbsData = [
    ...baseBreadcrumbsData,
    ...[{ text: post.title, path: `${writer.pathname}/${slug}` }]
  ]

  // 目次の各リンクにクリックイベント
  const handleTableOfContentsClick = (event) => {
    event.preventDefault();

    const targetId = event.target.getAttribute('href').substring(1);
    const targetElement = document.getElementById(targetId);
    const headerElement = document.getElementById('main-header');
    if (targetElement && headerElement) {
      const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY - headerElement.getBoundingClientRect().height - 20;
      window.scrollTo({
        left: 0,
        top: offsetTop,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    // 目次の各リンクにクリックイベントを追加
    const tocLinks = document.body.querySelectorAll("#toc_container a");

    tocLinks.forEach(link => {
      link.addEventListener('click', handleTableOfContentsClick);
    });

    // (async () => {
    //   const { data } = await ArticlesService.pageView({ article_id: slug })
    //   const res = await ArticlesService.incrementMonthlyPageView({ articleId: slug });
    //   if (data.statusCode !== 200 || res.data.statusCode !== 200) {
    //     // @Todo: notify error somewhere
    //   }
    // })()
  }, [slug, props.postData.body, props.isLoading])

  // useEffect(() => {
  //   dispatch(fetchArticles())
  // }, [dispatch])

  // TODO: 今後言語ごとに色をつけたい
  // useEffect(() => {
  //   Prism.highlightAll();
  // }, [props.postData.body]);

  const handleClick = useCallback((tagValue: string) => {
    navigate(`/tags/${tagValue}/`);
  }, [])

  const title = post.title + " | ネクストライブ株式会社"
  const url = typeof window !== `undefined` ? window.location.href : '';

  return (
    <>
      <Layout
        sidebar={true}
        hasBlogTitle={false}
        isBlogPostPage={true}
        breadcrumbsData={breadcrumbsData}
        slug={post.slug}
        category={post.category}
      >
        {
          props.isLoading ? <LoadingAPI /> :
            <MainContainer>
              <Head
                title={title}
                description={post.description}
                img={post.thumbnail}
              />
              <div>
                <ArticleHeading
                  category={post.category}
                  title={post.title}
                  tags={post.tags}
                  slug={slug}
                  date={formateDate(post.date)}
                />
                <AuthorWrapper>
                  <Author
                    avatar={writer.filename}
                    author={writer ? writer.name : post.author}
                    position={writer.position}
                    pathname={writer.pathname}
                    date={post.date}
                  />
                </AuthorWrapper>
                <TagsWrapper>
                  <TagList values={post.tags} onClick={handleClick} />
                </TagsWrapper>
              </div>
              <ThumbnailWrapper>
                <img src={post.thumbnail} />
              </ThumbnailWrapper>

              {/* {
                ["デザイン", "グラフィック", "イラスト", "クリエイティブ", "UI", "Web制作", "ビジネス"].some(i => post.tags.includes(i)) &&
                <BannerWrapper>
                  <Banner src={props.data.banner10IW.childImageSharp.fluid} alt="WEB制作パック｜大阪でホームページ制作はネクストライブへ" to='/lp/10-innovation-web/' linkType='outer' />
                </BannerWrapper>
              } */}

              {
                ["0→1 ENGINEER CAMP 法人向け", "プログラミング研修", "新人研修"].some(i => post.tags.includes(i)) &&
                <BannerWrapper>
                  <Banner src={props.data.banner01EC.childImageSharp.fluid} alt="0→1 ENGINEER CAMP（ゼロイチエンジニアキャンプ）｜実務レベルのエンジニアを育てる超実践型エンジニア育成サービス" className='bannerBlogPost01ECtoB' to='/lp/01engineer-camp/' linkType='outer' />
                </BannerWrapper>
              }

              {/* {
                ["NEXT ENGINEER PROJECT", "採用", "駆け出しエンジニア"].some(i => post.tags.includes(i)) &&
                <BannerWrapper>
                  <Banner src={props.data.bannerNEC.childImageSharp.fluid} alt="絶対エンジニアになれる無料プログラミング学習｜NEXT ENGINEER PROJECT" to='/lp/next-engineer-project/' linkType='outer' />
                </BannerWrapper>
              } */}

              <Node className={"markdown__wrapper"} dangerouslySetInnerHTML={{ __html: props.postData.body }} />
              <SNSShare title={title} url={url} />
              <AuthorWrapperBottom>
                <span className={"author_of_article"}>この記事を書いた人</span>
                <div className={"flexbox"}>
                  <Author
                    avatar={writer.filename}
                    author={writer ? writer.name : post.author}
                    position={writer.position}
                    pathname={writer.pathname}
                  />
                  <div className={"flexbox read"}>
                    <RoundLinkButton title={`この人の記事を読む`} url={`/blog/${writer.pathname}`} height={56} />
                  </div>
                </div>
              </AuthorWrapperBottom>
            </MainContainer>
        }
      </Layout>
    </>
  )
});

export { Component as WPBlogPost }